import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';

import { onAuthChanged } from '../../services/driver/firebase/auth';
// import { theme } from "./style";

export default function useAdminPage() {
  // const [mobileOpen, setMobileOpen] = useState(false);
  // const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  // const handleDrawerToggle = () => {
  //   setMobileOpen(!mobileOpen);
  // };

  const user = Cookies.get('user');
  const [signIn, setSignIn] = useState<any>(
    user ? JSON.parse(user) : undefined
  );

  useEffect(() => {
    onAuthChanged().then((user) => {
      setSignIn(user);
      Cookies.set('user', JSON.stringify(user));
    });
  }, []);

  return {
    // mobileOpen,
    // setMobileOpen,
    // isSmUp,
    // handleDrawerToggle,
    signIn,
    setSignIn,
    user
  };
}
