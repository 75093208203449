import { Suspense, lazy } from 'react';
import { RouteObject } from 'react-router';

import SuspenseLoader from 'src/components/SuspenseLoader';
import SidebarLayout from 'src/layouts/SidebarLayout';
import { ItemProvider } from './content/dashboards/items/ItemContext';

const Loader = (Component) => (props) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

const Overview = Loader(lazy(() => import('src/content/dashboards/Overview')));
const Receipt = {
  List: Loader(lazy(() => import('src/content/dashboards/receipt/List'))),
};
const Item = {
  List: Loader(lazy(() => import('src/content/dashboards/items/List'))),
  View: Loader(lazy(() => import('src/content/dashboards/items/View'))),
  Create: Loader(lazy(() => import('src/content/dashboards/items/Create'))),
  Edit: Loader(lazy(() => import('src/content/dashboards/items/Edit')))
};

const routes: RouteObject[] = [
  {
    path: '',
    element: <SidebarLayout />,
    children: [
      {
        path: '/',
        element: <Overview />
      },
      {
        path: '/receipt',
        children: [
          {
            path: '/receipt',
            element: <Receipt.List />
          }
        ]
      },
      {
        path: '/shop',
        element: <ItemProvider itemType={'shop'} />,
        children: [
          {
            path: '/shop',
            element: <Item.List />
          },
          {
            path: '/shop/create',
            caseSensitive: true,
            element: <Item.Create />
          },
          {
            path: '/shop/edit/:id',
            caseSensitive: true,
            element: <Item.Edit />
          },
          {
            path: '/shop/:id',
            element: <Item.View />
          }
        ]
      },
      {
        path: '/machine',
        element: <ItemProvider itemType={'machine'} />,
        children: [
          {
            path: '/machine',
            element: <Item.List />
          },
          {
            path: '/machine/create',
            caseSensitive: true,
            element: <Item.Create />
          },
          {
            path: '/machine/edit/:id',
            caseSensitive: true,
            element: <Item.Edit />
          },
          {
            path: '/machine/:id',
            element: <Item.View />
          }
        ]
      },
      {
        path: '/product',
        element: <ItemProvider itemType={'product'} />,
        children: [
          {
            path: '/product',
            element: <Item.List />
          },
          {
            path: '/product/create',
            caseSensitive: true,
            element: <Item.Create />
          },
          {
            path: '/product/edit/:id',
            caseSensitive: true,
            element: <Item.Edit />
          },
          {
            path: '/product/:id',
            element: <Item.View />
          }
        ]
      }
    ]
  }
];

export default routes;
