export enum ItemStatus {
  PUBLISH = 'publish',
  DRAFT = 'draft'
}

export type ItemType = 'shop' | 'machine' | 'product';

export type Tag = { type: 'system' | 'item' | 'sale'; value: string };

export type Price = {
  basePrice: number;
  salePrice?: number;
  currencyCode: 'usd' | 'khr';
};

export interface Item {
  id: string;
  title: string;
  description: string;
  price: Price;
  imageUrls?: Array<string>;
  videoUrls?: Array<string>;
  specification?: string;
  itemType: ItemType;
  category: string;
  tags?: Array<Tag>;
  status: ItemStatus;
  authorId: string;
  updateById?: string;
  createDate: number;
  updateDate?: number;
}

export const COMMON_TAGS: Array<Tag> = [
  { type: 'system', value: 'Feature' },
  { type: 'sale', value: 'New' },
  { type: 'item', value: 'Pre-order' },
  { type: 'item', value: 'In Stock' },
  { type: 'item', value: 'Out of Stock' },
  { type: 'item', value: '3D Printer' },
  { type: 'item', value: 'C02 Laser' },
  { type: 'item', value: 'Laser Diode' },
  { type: 'item', value: 'Fiber Making' },
  { type: 'item', value: 'CNC Router' },
  { type: 'item', value: 'Foam Cutting' }
];

export const COMMON_CATEGORY = {
  shop: ['Tool', 'Accessory', 'Other'],
  machine: [
    '3D Printer',
    'C02 Laser',
    'Laser Diode',
    'Fiber Making',
    'CNC Router',
    'Foam Cutting'
  ],
  product: [
    '3D Printer',
    'C02 Laser',
    'Laser Diode',
    'Fiber Making',
    'CNC Router',
    'Foam Cutting'
  ]
};
