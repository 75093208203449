import { createContext } from 'react';
import { Outlet } from 'react-router';
import { COMMON_CATEGORY, ItemType } from 'src/models/item';

export type ItemContextType = {
  itemType: ItemType;
  itemCategories: Array<string>;
};

export const ItemContext = createContext<ItemContextType>({
  itemType: 'shop',
  itemCategories: COMMON_CATEGORY.shop
});

export const ItemProvider = ({ itemType }: { itemType: ItemType }) => {
  const itemCategories = COMMON_CATEGORY[itemType] || [];

  return (
    <ItemContext.Provider
      value={{
        itemType,
        itemCategories: itemCategories
      }}
    >
      <Outlet />
    </ItemContext.Provider>
  );
};
