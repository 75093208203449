import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut
} from 'firebase/auth';
import firebaseApp from './client';

const firebase = firebaseApp().firebase;
export const onAuthChanged = () => {
  const auth = getAuth();

  return new Promise((resolve, reject) =>
    onAuthStateChanged(auth, (user) => resolve(user))
  );
};

export const signIn = (email: string, password: string) => {
  const auth = getAuth();

  return new Promise((resolve, reject) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        resolve(user);
      })
      .catch((error) => {
        const errorMessage = error.message;
        reject(errorMessage);
      });
  });
};

export const signout = () => {
  const auth = getAuth();

  signOut(auth)
    .then(() => {})
    .catch(() => {})
    .finally(() => {
      if (window) window.location.reload();
    });
};
